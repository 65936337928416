import { Injectable, OnDestroy } from "@angular/core";
import { BehaviorSubject, Observable, Subscription } from "rxjs";
import { ProductFilterOption } from "src/@omnial/_models/catalog/product-filter.model";
import { Product } from "src/@omnial/_models/catalog/product.model";
import { ProductsPaged } from "src/@omnial/_models/catalog/products.paged.model";
import { ProductSearchResults } from "src/@omnial/_models/search/product.search-results.model";
import { environment } from "src/environments/environment";
import { RepositorySearchService } from "../repository-search.service";

@Injectable()
export class ProductSearchService implements OnDestroy {
  private currentTerm: string;
  private pageIndex: number = 0;
  public totalHits: number = 0;
  public searchProducts: BehaviorSubject<ProductsPaged> = new BehaviorSubject<ProductsPaged>(null);
  private $searchProducts: ProductsPaged = null;

  private subscriptions: Subscription[] = [];

  constructor(public repoSearchService: RepositorySearchService) { }

  ngOnDestroy(): void {
    if (this.subscriptions && this.subscriptions.length > 0) {
      this.subscriptions.forEach((sub) => { sub.unsubscribe() });
    }
  }

  public load(): void {
    this.$searchProducts = null;
    this.searchProducts.next(null);
  }

  public replaceProducts(products: ProductsPaged): void {
    this.$searchProducts = products;
    this.searchProducts.next(this.$searchProducts);
  }

  public searchProductsPaged(term: string, pageIndex: number, pageSize: number, filters: ProductFilterOption[]): Observable<ProductSearchResults> {
    return new Observable((observer) => {
      this.subscriptions.push(this.repoSearchService.searchProductsPaged(term, pageIndex, pageSize, filters).subscribe({
        next: (res) => {
          observer.next(res);
          observer.complete();
        }
      }));
    });
  }

  public getSearchProducts(term: string, filters: ProductFilterOption[]) {
    this.currentTerm = term;
    this.pageIndex = 0;
    this.replaceProducts(null);
    this.getSearchProductsRecursive(term, filters);
  }

  private getSearchProductsRecursive(term: string, filters: ProductFilterOption[]) {
    if (!term || this.currentTerm !== term) { // To stop recursive calls no longer needed
      return;
    }
    if (this.pageIndex * environment.pageSize > this.totalHits) {
      if (this.subscriptions && this.subscriptions.length > 0) {
        this.subscriptions.forEach((sub) => {
          sub.unsubscribe();
        });
      }
    } else {
      const subscription = this.searchProductsPaged(term, this.pageIndex, environment.pageSize, filters).subscribe({
        next: (res) => {
          if (term && this.currentTerm === term) { // To stop recursive calls no longer needed
            const page = new ProductsPaged();
            if (this.pageIndex == 0) {
              this.totalHits = res.hits;
              page.filterGroups = res.filterGroups;
            }
            page.products = res.products as Product[];
            page.pageIndex = this.pageIndex;
            page.pageSize = environment.pageSize;
            page.lastPage = ((this.pageIndex + 1) * environment.pageSize > this.totalHits - 1) || this.pageIndex >= 10;
            page.seName = 'search';
            page.visible = page.pageIndex === 0;
            this.replaceProducts(page);
            try {
              subscription.unsubscribe();
            } catch (e) {
              // Nothing - Not initialised - TODO - Deal with this.
            }
            this.pageIndex++;
            if (this.pageIndex <= 10) {
              this.getSearchProductsRecursive(term, filters);
            }
          } else {
            this.replaceProducts(null);
          }
        }
      });
    }
  }
}

